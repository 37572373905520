var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "create-applicant"
  }, [_c('p', {
    staticClass: "text-2xl font-bold mb-10"
  }, [_vm._v("Data Pemohon")]), _c('div', {
    staticClass: "bg-white"
  }, [_c('div', {
    staticClass: "flex items-stretch shadow-border-b mdmax:flex-col"
  }, [_c('div', {
    staticClass: "flex-1 p-6 shadow-border-r mdmax:pb-0"
  }, [_c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "label": "Tanggal saat ini",
      "disabled": ""
    },
    model: {
      value: _vm.payload.currentDate,
      callback: function ($$v) {
        _vm.$set(_vm.payload, "currentDate", $$v);
      },
      expression: "payload.currentDate"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "label": "Nama Lengkap",
      "placeholder": "Masukan nama lengkap"
    },
    model: {
      value: _vm.payload.applicantName,
      callback: function ($$v) {
        _vm.$set(_vm.payload, "applicantName", $$v);
      },
      expression: "payload.applicantName"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "label": "Nomor Kependudukan (KTP)",
      "placeholder": "Masukan nomor KTP"
    },
    model: {
      value: _vm.payload.applicantIdCardNumber,
      callback: function ($$v) {
        _vm.$set(_vm.payload, "applicantIdCardNumber", $$v);
      },
      expression: "payload.applicantIdCardNumber"
    }
  })], 1), _c('div', {}, [_c('label', {
    staticClass: "text-sm text-neutral-700 font-medium mb-1 inline-block"
  }, [_vm._v("Jenis Kelamin")]), _c('CustomSelect', {
    attrs: {
      "options": _vm.genders,
      "optionLabel": "value",
      "placeholder": "Pilih jenis kelamin"
    },
    model: {
      value: _vm.payload.selectedGender,
      callback: function ($$v) {
        _vm.$set(_vm.payload, "selectedGender", $$v);
      },
      expression: "payload.selectedGender"
    }
  })], 1)]), _c('div', {
    staticClass: "flex-1 p-6 shadow-border-r"
  }, [_c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-sm text-neutral-700 font-medium mb-1 inline-block"
  }, [_vm._v("Hasil Kelulusan")]), _c('CustomSelect', {
    attrs: {
      "options": _vm.result,
      "optionLabel": "value",
      "placeholder": "Pilih lulus atau tidak lulus"
    },
    model: {
      value: _vm.payload.selectedResult,
      callback: function ($$v) {
        _vm.$set(_vm.payload, "selectedResult", $$v);
      },
      expression: "payload.selectedResult"
    }
  })], 1), _vm.payload.selectedLocation ? _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-sm text-neutral-700 font-medium mb-1 inline-block"
  }, [_vm._v("Lokasi Test")]), _c('div', {
    staticClass: "flex items-center gap-2"
  }, [_c('Place'), _c('p', {
    staticClass: "text-neutral-500"
  }, [_vm._v(_vm._s(_vm.payload.selectedLocation.name))])], 1)]) : _vm._e(), _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-sm text-neutral-700 font-medium mb-1 inline-block"
  }, [_vm._v("Jenis Permohonan")]), _c('CustomSelect', {
    attrs: {
      "options": _vm.request_sim_purpose,
      "optionLabel": "value",
      "placeholder": "Pilih jenis permohonan"
    },
    model: {
      value: _vm.payload.request_sim_purpose,
      callback: function ($$v) {
        _vm.$set(_vm.payload, "request_sim_purpose", $$v);
      },
      expression: "payload.request_sim_purpose"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-sm text-neutral-700 font-medium mb-1 inline-block"
  }, [_vm._v("Pilih SIM")]), _c('CustomSelect', {
    attrs: {
      "options": _vm.simType,
      "optionLabel": "value",
      "placeholder": "Pilih tipe SIM"
    },
    model: {
      value: _vm.payload.simType,
      callback: function ($$v) {
        _vm.$set(_vm.payload, "simType", $$v);
      },
      expression: "payload.simType"
    }
  })], 1)])]), _c('div', {
    staticClass: "flex justify-end items-center gap-2 p-6"
  }, [_c('LinkButton', {
    attrs: {
      "buttonText": "Batal",
      "url": `/applicant`,
      "linkType": "internal",
      "type": "secondary"
    }
  }), _c('Button', {
    attrs: {
      "buttonText": "Submit",
      "disabled": _vm.isDisabled
    },
    on: {
      "action": function ($event) {
        return _vm.submit();
      }
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }