var render = function render(){
  var _vm$applicantResultDe, _vm$applicantResultDe2, _vm$applicantResultDe3, _vm$applicantResultDe4, _vm$applicantResultDe5, _vm$applicantResultDe6, _vm$applicantResultDe7, _vm$applicantResultDe8, _vm$applicantResultDe9, _vm$applicantResultDe10, _vm$applicantResultDe11, _vm$applicantResultDe12, _vm$applicantResultDe13, _vm$applicantResultDe14, _vm$applicantResultDe15, _vm$applicantResultDe16, _vm$applicantResultDe17, _vm$applicantResultDe18, _vm$applicantResultDe19;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "result-detail"
  }, [_c('div', {
    staticClass: "flex justify-between items-center mb-10 mdmax:flex-col mdmax:gap-4 mdmax:mb-6"
  }, [_c('p', {
    staticClass: "text-2xl font-bold"
  }, [_vm._v(_vm._s((_vm$applicantResultDe = _vm.applicantResultDetail) === null || _vm$applicantResultDe === void 0 ? void 0 : (_vm$applicantResultDe2 = _vm$applicantResultDe.applicant_info) === null || _vm$applicantResultDe2 === void 0 ? void 0 : _vm$applicantResultDe2.name))]), _c('Button', {
    attrs: {
      "buttonText": "Download PDF",
      "size": _vm.isMobile ? 'big_full' : 'compact'
    },
    on: {
      "action": function ($event) {
        return _vm.download();
      }
    }
  })], 1), _c('div', {
    staticClass: "bg-white p-6"
  }, [_c('div', {
    staticClass: "flex justify-between gap-6 items-stretch mdmax:flex-col mdmax:w-full"
  }, [_c('div', {
    staticClass: "w-3/5 flex items-stretch gap-6 mdmax:w-full mdmax:flex-col"
  }, [_c('div', {
    staticClass: "flex-1"
  }, [_c('p', {
    staticClass: "font-bold text-lg mb-8"
  }, [_vm._v("Data Diri")]), _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Nama")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantResultDe3 = _vm.applicantResultDetail) === null || _vm$applicantResultDe3 === void 0 ? void 0 : (_vm$applicantResultDe4 = _vm$applicantResultDe3.applicant_info) === null || _vm$applicantResultDe4 === void 0 ? void 0 : _vm$applicantResultDe4.name))])]), _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Nomor Kependudukan (KTP)")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantResultDe5 = _vm.applicantResultDetail) === null || _vm$applicantResultDe5 === void 0 ? void 0 : (_vm$applicantResultDe6 = _vm$applicantResultDe5.applicant_info) === null || _vm$applicantResultDe6 === void 0 ? void 0 : _vm$applicantResultDe6.id_card_no))])]), _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Jenis Kelamin")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s(((_vm$applicantResultDe7 = _vm.applicantResultDetail) === null || _vm$applicantResultDe7 === void 0 ? void 0 : (_vm$applicantResultDe8 = _vm$applicantResultDe7.applicant_info) === null || _vm$applicantResultDe8 === void 0 ? void 0 : _vm$applicantResultDe8.gender) === 'M' ? 'Pria' : 'Wanita'))])]), _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Jenis Permohonan")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(" " + _vm._s(((_vm$applicantResultDe9 = _vm.applicantResultDetail) === null || _vm$applicantResultDe9 === void 0 ? void 0 : _vm$applicantResultDe9.sim_type) === '' ? 'SIM A dan atau SIM C' : `SIM ${(_vm$applicantResultDe10 = _vm.applicantResultDetail) === null || _vm$applicantResultDe10 === void 0 ? void 0 : _vm$applicantResultDe10.sim_type}`) + " " + _vm._s(((_vm$applicantResultDe11 = _vm.applicantResultDetail) === null || _vm$applicantResultDe11 === void 0 ? void 0 : _vm$applicantResultDe11.request_sim_purpose) == 'NEW' ? '- BARU' : ((_vm$applicantResultDe12 = _vm.applicantResultDetail) === null || _vm$applicantResultDe12 === void 0 ? void 0 : _vm$applicantResultDe12.request_sim_purpose) == 'EXTEND' ? '- PERPANJANGAN' : '') + " ")])])]), _c('div', {
    staticClass: "flex-1"
  }, [_c('p', {
    staticClass: "font-bold text-lg mb-8"
  }, [_vm._v("Lokasi Test")]), _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Lokasi Test")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantResultDe13 = _vm.applicantResultDetail) === null || _vm$applicantResultDe13 === void 0 ? void 0 : (_vm$applicantResultDe14 = _vm$applicantResultDe13.schedule_info) === null || _vm$applicantResultDe14 === void 0 ? void 0 : _vm$applicantResultDe14.location_test_display))])]), _c('div', {
    staticClass: "mb-6"
  }, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Penanggung Jawab Psikolog")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s((_vm$applicantResultDe15 = _vm.applicantResultDetail) === null || _vm$applicantResultDe15 === void 0 ? void 0 : _vm$applicantResultDe15.responsible_psychologist))])]), _c('div', {}, [_c('label', {
    staticClass: "text-sm text-secondary mb-1 block"
  }, [_vm._v("Tanggal Test")]), _c('p', {
    staticClass: "text-lg mdmax:text-base"
  }, [_vm._v(_vm._s(_vm._f("formatDate")((_vm$applicantResultDe16 = _vm.applicantResultDetail) === null || _vm$applicantResultDe16 === void 0 ? void 0 : (_vm$applicantResultDe17 = _vm$applicantResultDe16.schedule_info) === null || _vm$applicantResultDe17 === void 0 ? void 0 : _vm$applicantResultDe17.schedule_time)))])])])]), _c('div', {
    staticClass: "w-2/5 flex justify-end mdmax:w-full mdmax:justify-center"
  }, [_c('div', {
    staticClass: "text-right mdmax:text-center"
  }, [_c('span', {
    staticClass: "inline-block text-3xl whitespace-nowrap font-medium px-2 py-2 rounded-md",
    class: [((_vm$applicantResultDe18 = _vm.applicantResultDetail) === null || _vm$applicantResultDe18 === void 0 ? void 0 : _vm$applicantResultDe18.score_status) === 'passed' ? 'bg-green-light text-green border-4 border-green' : 'bg-red-light text-system-error border-4 border-system-error']
  }, [_vm._v(" " + _vm._s((_vm$applicantResultDe19 = _vm.applicantResultDetail) === null || _vm$applicantResultDe19 === void 0 ? void 0 : _vm$applicantResultDe19.score_status_display) + " ")]), _c('div', {
    staticClass: "mt-32 mdmax:mt-10"
  }, [_c('qrcode-vue', {
    attrs: {
      "value": _vm.applicantResultDetail.qr_content,
      "size": 200,
      "level": "H"
    }
  })], 1)])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }